//@import "../CommonStyleParams.scss";
.redirectMessage {
    color: white;
    background-color: red;
    padding: 25px 15px 20px 15px;
    text-align: center;
}

.header {
    height: 60px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #222425;
    color: #cdcdce;
    font-size: 2rem;
    padding: 10px;
    z-index: 2;



    .appName {
        padding-left: 30px;

        &>a {
            text-decoration: none;
            color: #cdcdce;
            cursor: pointer;
        }

        img.freelancerLogo {
            width: 200px;

            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }

        @media screen and (max-width: 768px) {
            padding-left: 0px;
            width: 50%;
        }
    }

    .companyLogoContainer {
        height: 100%;

        .companyLogo {
            height: 100%;
            // width: 100%;
        }



        @media screen and (max-width: 480px) {
            width: 50%;

            .companyLogo {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 480px) {
        font-size: 1rem;
    }
}

.mainContent {
    display: flex;
    flex: 1;
    padding: 20px;
    flex-direction: column;
    overflow-y: auto;

    @media screen and (max-width: 768px) {
        padding: 0px;
    }

    &>div {
        flex: 1;
    }

    .pageHeading {
        color: #467abd; //rgb(57, 129, 207);
        margin: 0; //0 0 10px 0;
        font-weight: 500;
        font-size: 20px;
    }

    .container {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        max-width: 1200px;
        margin: auto;
    }
}

.footer {
    height: 40px;
    background-color: #222425;
    color: #cdcdce;
    font-size: 1.2rem;
    padding: 10px;
    padding-left: 40px;

    @media screen and (max-width: 480px) {
        font-size: 1rem;
        padding-left: 10px;
        justify-content: center;
        display: inline-table;
    }

    .footerLink {
        color: white;
        font-size: 0.9rem;
    }

    .footerseparater {
        margin-left: 20px;
        margin-right: 20px;
    }
}