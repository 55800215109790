.googleButton{
    width:200px !important; 
    font-size: 14px !important;
    font-weight: 500 !important; 
    font-family: Roboto, sans-serif !important;
}

.googleLogoutButton{
    width:120px !important;
    font-size: 14px !important;
    font-weight: 500 !important; 
    font-family: Roboto, sans-serif !important;
    margin-top:10px;
}