.disablelbl {
    color: rgb(161, 159, 157);
    ;
}

.disableRating {
    .ms-RatingStar-back {
        color: rgb(200, 198, 196);
    }

    .ms-RatingStar-front {
        color: rgb(200, 198, 196);
    }
}

.userProfileStatusSection {
    margin-bottom: 10px;
    padding: 10px;
    text-align: center;
    background-color: antiquewhite;
    color: white;

    .lblStatusHeader {
        padding-right: 5px;
        font-weight: 600;
    }

    .lblStatusValue {
        padding-right: 5px;
    }
}

.profilePasswordSection {
    border-width: 1px;
    border-style: dashed;
    padding: 5px 10px 5px 5px;
}

.profileStatusdll>div.ms-Dropdown-container {
    margin-top: 10px;
}

.profileStatusdll>div.ms-Dropdown-container>label {
    float: left;
    margin-right: 10px;
}

.profileStatusdll>div.ms-Dropdown-container>div {
    float: left;
    width: 200px;
}

.userProfileStack {
    width: 50%;

    @media screen and (max-width: 480px) {
        width: 100%;
    }
}

.vl {
    border-left: 1px solid black;
    padding-left: 2px;
    padding-right: 2px;
}

.profileActionBTN {
    margin-top: 10px;
    display: block;
    text-align: right;

    @media screen and (max-width: 480px) {
        display: flex !important;
    }
}


.userProfileStack .userProfileItem {
    margin-bottom: 10px;
}

.userProfileStack .jobSelection {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.userProfileStack .userProfileItem label {
    float: left;
    width: 150px;
    margin-right: 15px;

    @media screen and (max-width: 480px) {
        float: initial;
    }
}

.userProfileStack .adminJobConfirm {
    width: 30%;
}

.userProfileStack .jobSelection label {

    @media screen and (max-width: 480px) {
        float: left !important;
    }
}

.userProfileSectionDiv {
    @media screen and (max-width: 480px) {
        display: block;
    }

    .userProfileChoiceFields {

        @media screen and (max-width: 480px) {
            margin-left: 0px;
        }

        .optionsLabel {
            height: 40px;

            @media screen and (max-width: 480px) {
                // margin-left: 0px;
                height: 70px;
            }
        }
    }

}

.userProfileTab {
    width: 100%;

    @media screen and (max-width: 480px) {
        display: block;
        height: 100%;
    }
}

.noDataMessage {
    padding: 30px;
    margin: 35px;
    background-color: green;
    color: white;
    text-align: center;

    @media screen and (max-width: 480px) {
        padding: 5px;
        margin: 0px;
        background-color: green;
        color: white;
        text-align: center;
    }
}

.iconAlertColor {
    padding-left: 2px;
    color: blue;
}

.userProfileChoiceFields .userProfileItem {
    padding-left: 20px;
    padding-bottom: 0px !important;

    @media screen and (max-width: 480px) {
        padding-left: 5px;
    }
}

.userProfileStack .userProfileItem label.ms-Checkbox-label .ms-Checkbox-checkbox {
    top: 5px;
}

.straumannBlueButton {
    margin: auto;
    width: 200px;
    border-radius: 0px !important;
}

.straumannBlueButton .ms-Button-flexContainer {
    display: inline-flex !important;
}