
#customBtn {
    display: inline-block;
    background: white;
    color: #444;
    width: 190px;
    border-radius: 5px;
    border: thin solid #888;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;
    line-height: 15px;
} #customBtn:hover {
    cursor: pointer;
}



span.label {
    font-family: serif;
    font-weight: normal;
}

span.icon {
    background: url('/../../images/iconGoogle.png') transparent  5px 50% no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
}

span.buttonText {
    display: inline-block;
    vertical-align: middle;
    padding-left: 42px;
    padding-right: 42px;
    font-size: 14px;
    font-weight: bold;
    /* Use the Roboto font that is loaded in the <head> */
    font-family: 'Roboto', sans-serif;
}
button.microsoftButton {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMSIgdmlld0JveD0iMCAwIDIxIDIxIj48dGl0bGU+TVMtU3ltYm9sTG9ja3VwPC90aXRsZT48cmVjdCB4PSIxIiB5PSIxIiB3aWR0aD0iOSIgaGVpZ2h0PSI5IiBmaWxsPSIjZjI1MDIyIi8+PHJlY3QgeD0iMSIgeT0iMTEiIHdpZHRoPSI5IiBoZWlnaHQ9IjkiIGZpbGw9IiMwMGE0ZWYiLz48cmVjdCB4PSIxMSIgeT0iMSIgd2lkdGg9IjkiIGhlaWdodD0iOSIgZmlsbD0iIzdmYmEwMCIvPjxyZWN0IHg9IjExIiB5PSIxMSIgd2lkdGg9IjkiIGhlaWdodD0iOSIgZmlsbD0iI2ZmYjkwMCIvPjwvc3ZnPg==);
    background-repeat: no-repeat;
    width: 205px;
    height: 42px;
    background-position: 10px;
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.54);
    padding-left: 40px;
    border-radius: 2px;
    border: 1px solid transparent;
    box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
}
button.customButton {
    background-image: url(data:image/jpg;base64,/9j/4AAQSkZJRgABAgEAYABgAAD//gASTEVBRFRPT0xTIHYyMC4wAP/bAIQABQUFCAUIDAcHDAwJCQkMDQwMDAwNDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQEFCAgKBwoMBwcMDQwKDA0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0N/8QBogAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoLAQADAQEBAQEBAQEBAAAAAAAAAQIDBAUGBwgJCgsQAAIBAwMCBAMFBQQEAAABfQECAwAEEQUSITFBBhNRYQcicRQygZGhCCNCscEVUtHwJDNicoIJChYXGBkaJSYnKCkqNDU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6g4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2drh4uPk5ebn6Onq8fLz9PX29/j5+hEAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/8AAEQgAJgAnAwERAAIRAQMRAf/aAAwDAQACEQMRAD8A+y6ACgChqeqWujW73l/KlvBEMs8hwB6Adyx6KoBZjwoJOKAPlXx58crnU99j4d3WltyrXJ4nkHT92P8Alip7N/rSMHMZytMR7r8J2L+FrBmJJKSEk8kkzy5JPcmkB6JQM8w8efFPTfBINt/x96gQCtuhwEyMhpnwRGCOQoDSMCCFCncAR8eeJvF+reNroS38jSndiGCMERR7jgLFGM/MeBuO6RuAzHApiPTvBfwiikubf/hKpls2usm307eFupwoLEvg5iTAOVH7w8qTE+AQLH1zY2FvpkCWlnGsEEK7UjQbVUewHqcknqSSSSSTSKLdAH5+2PhDXPGGq3SbD5yTym8uJSVhhcOxkMkhHGDkhQC5HIXAJDJOsfX9E+HIMHh0JqmrgFX1OVQYYSRgi0j5BPJ/eEkH+9Ih2gDY5/wMdU8UeK7K6Z5bm4W6iuJ5WJJWGJ1aQs3RU2Aoq8LlljUfMBQB97UijE8R6/beF9Pm1W93GC2Clgg3MS7rGiqMgZZ3UZJAGckgAmgD4n8bfE7UfFxe3jAsdPZi32aI48wk5LTuADK5PJBATODt3fMWSX/Anwj1LxeVurjNjpx5851+eUf9MUOMg/8APRsIOSN5BWgLH2D4Z8J6b4RtvsulwiIHG9z80spH8Uj9WPXA4VckKqjikUdHQB5j8ZRnwlfD1+zf+llvQI474e/Bax0yOLU9ZKX1y6rJHFg/Z49wDKSrAGV8Y++AgORsYgNTCx7+AAMDgCkMKACgD//Z);
    background-repeat: no-repeat;
    width: 200px;
    height: 42px;
    background-position: left;
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.54);
    padding-left: 40px;
    border-radius: 2px;
    border: 1px solid transparent;
    box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
    margin-top: 5px;
    cursor: pointer;
    line-height: 15px;
}

button.customButton:disabled{
    color: #ddd;
    background-blend-mode: hard-light
}