$mobileResolution: 480px;

.NewEventMailIcon {
    color: #467abd !important;
    ;
    font-weight: 800;
    cursor: pointer;
}

.blockBackground {
    overflow: hidden !important;
}

.fc-timegrid-slot-lane,
.dayViewJobOpen {
    cursor: pointer;
}

.profileActionBTN {
    .noSubmissionAvailable {
        font-size: 1.5em;
        font-weight: 600;
        color: green;
    }
}

.eventAttendance {

    @media screen and (max-width: 480px) {
        overflow: auto;
    }

    table.eventUsers {
        @media screen and (max-width: 480px) {
            table-layout: fixed;
        }

        .userEvent {
            td {
                vertical-align: middle;
                padding: 0.5rem !important;
            }

            .email {
                width: 250px;
                word-break: break-all;

                .CommentAddIcon {
                    cursor: pointer !important;
                    color: #467abd !important;
                    margin-left: 5px;
                }

            }

            .Job {
                width: 120px;

            }

            .Position {
                width: 100px;

                // @media screen and (max-width: 480px) {
                //     display: block;
                // }
            }

            .Present {
                width: 90px;
                text-align: center;

                .IsPresentChk {
                    text-align: center;
                    display: inline-block;

                    label {
                        display: inline-block;
                    }
                }
            }

            .Start {
                width: 150px;

                // @media screen and (max-width: 480px) {
                //     display: flex;
                // }

                .timeCtrl {
                    float: left;
                    width: 49%;
                }
            }

            .End {
                width: 150px;

                // @media screen and (max-width: 480px) {
                //     display: flex;
                // }

                .timeCtrl {
                    float: left;
                    width: 49%;
                }
            }

            .Hours {
                width: 75px;

                // @media screen and (max-width: 480px) {
                //     display: flex;
                // }
            }

            .Rating {
                width: 135px;

                .ratingClass .ms-RatingStar-back,
                .ratingClass .ms-RatingStar-front {
                    color: #467abd !important;
                    ;
                }
            }
        }
    }


}

.eventClassNames {
    background-color: blueviolet;
}



.dayViewJobCompleted {
    background-color: gray;
}

.dayViewJobOpen {
    background-color: #467abd;
}

.eventJobClosed {
    background-color: gray !important;
    cursor: pointer;

    .fc-daygrid-day-events {
        display: none;
    }

    .fc-daygrid-day-number {
        color: white;
    }
}

// .fc-daygrid-day-events {
//     display: none;
// }

.eventJobOpened {
    background-color: #467abd !important;

    .fc-daygrid-day-events {
        display: none;
    }

    .fc-daygrid-day-number {
        color: white;
    }
}

// .fc-timegrid-event-harness{
//     .fc-timegrid-event{

//     }
// }
.demo-app .addNewEventContainer {
    display: none;

    @media screen and (max-width: 768px) {
        position: fixed;
        // top: 170px;
        bottom: 100px;
        right: 20px;
        z-index: 1000;
        /* font-size: 22px; */
        border: none;
        outline: none;
        background-color: darkgray;
        cursor: pointer;
        padding: 15px;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        display: block;
        text-align: center;
    }

    .addNewEvent {
        @media screen and (max-width: 768px) {
            top: 8px;
            right: 15px;
            position: absolute;
        }

        .AddEventIcon {
            @media screen and (max-width: 768px) {
                color: white;
                font-size: 30px;
            }
        }
    }

}

.demo-app .calendar-app-main {
    .fc-daygrid-day {
        cursor: pointer;
    }

    .fc-today-button {
        cursor: pointer;
    }
}

.calendar-app-main .fc-media-screen .fc-view-harness-active {
    height: 500px !important;

    @media screen and (max-width: 361px) {
        height: 420px !important;
    }

    // @media screen and (min-width: 376px) and (max-width: 394px) {
    //     height: 520px !important;
    // }

    @media screen and (min-width: 361px) and (max-width: 376px) {
        height: 330px !important;
    }

    @media screen and (min-width: 376px) and (max-width: 394px) {
        height: 520px !important;
    }

    @media screen and (min-width: 394px) and (max-width: 768px) {
        /* STYLES HERE */
        height: 570px !important;

    }

}

.calendar-app-main .fc-header-toolbar {
    @media screen and (max-width: $mobileResolution) {
        // width: 100%;
        // text-align: center;
        // display: initial;
        // margin-bottom: 0.5em;
        display: flex;
        flex-direction: column-reverse;
    }

    .fc-toolbar-title {
        @media screen and (max-width: 768px) {
            font-size: 1.2em;
        }
    }

    .fc-toolbar-chunk {

        @media screen and (max-width: $mobileResolution) {
            width: 100%;
            text-align: center;
        }

    }

    .fc-toolbar-chunk:nth-child(1) {
        .fc-button-group {
            @media screen and (max-width: 768px) {
                width: 50%;
            }
        }

        .fc-today-button {
            @media screen and (max-width: 768px) {
                width: 48%;
                margin: 0px;
                margin-left: 5px;
            }
        }
    }

    .fc-toolbar-chunk:nth-child(3) {
        .fc-button-group {
            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
    }

}

.applicantRegSection {
    .eventApplicantSec {
        padding: 0px 0px 5px 0px;

        .eventApplicant {
            @media screen and (max-width: $mobileResolution) {
                display: inherit;
            }

            .eventJob {
                width: 33%;

                @media screen and (max-width: $mobileResolution) {
                    width: 100%;
                }

                .eventJobDiv {
                    margin: 0px 5px 5px 5px;

                    .jobTitle {
                        font-weight: 600;
                        padding-left: 5px;
                    }

                    .jobApplicant {
                        border-color: #797a7b;
                        border-style: solid;
                        padding: 5px;

                        .applicantName {
                            margin-bottom: 5px;
                            clear: both;

                            .name {
                                cursor: pointer;
                                float: left;
                                display: block;
                                width: 75%;
                            }

                            .type {
                                float: right;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }
    }
}

.modalDialogMail {
    .foolter {
        padding-bottom: 15px;
        padding-top: 10px;
    }

    .ms-Dialog-main {

        // min-width: 500px;
        .lblMailError {
            color: red;
        }

        @media screen and (max-width: 480px) {
            min-width: -webkit-fill-available !important;
        }

        .applicantMailSec {

            .mailBodytxt {
                padding-top: 10px;

                .ql-container {
                    height: 130px;
                }

                .ql-tooltip {
                    left: 0px !important;
                }
            }

            .eventApplicantSec {
                padding: 0px 0px 5px 0px;

                .eventApplicant {
                    display: inherit;
                    overflow-y: auto;
                    max-height: 175px;

                    @media screen and (max-width: 480px) {
                        overflow-y: initial;
                        max-height: initial;
                    }

                    .eventJob {
                        width: 100%;

                        .eventJobDiv {
                            margin: 0px 5px 5px 5px;

                            .jobTitle {
                                font-weight: 600;
                                padding-left: 5px;
                            }

                            .jobApplicant {
                                border-color: #797a7b;
                                border-style: solid;
                                padding: 5px;
                                padding-bottom: 0px;

                                .applicantName {
                                    margin-bottom: 5px;
                                    clear: both;

                                    .mailCheck {
                                        float: left;
                                    }

                                    .name {
                                        cursor: pointer;
                                        float: left;
                                        display: block;
                                        width: 65%;
                                    }

                                    .type {
                                        float: right;
                                        font-weight: bold;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}