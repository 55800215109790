.userSearch {
    .ms-TextField-field {
        width: 95% !important;
    }

    i {
        pointer-events: auto !important;
        cursor: pointer !important;
        font-size: 16px;
        margin: 0px 4px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        flex-shrink: 0;
        color: #467abd !important;;
    }
}

.searchSection .searchOptions label {
    float: left;
    margin-right: 15px;

    @media screen and (max-width: 480px) {
        float: initial;
        margin-right: 0px;
    }
}

.searchSection .searchOptions div>div {
    float: right;

    @media screen and (max-width: 480px) {
        float: initial;
    }
}

.searchSection>.searchOptions>.ms-Dropdown-container>.ms-Dropdown {

    @media screen and (max-width: 480px) {
        width: 100%;
    }
}

.searchSection .searchOptions {

    @media screen and (max-width: 480px) {
        margin-left: 0px;
    }
}

.searchSection {
    @media screen and (max-width: 480px) {
        display: block;
    }
}

.actionIcon {
    color: red;
    cursor: pointer;
}

.noDataAvailable {
    margin-top: 10px;
    width: 100%;
    text-align: center;
    padding: 50px 0px 50px 0px;
    background-color: gray;
}