.eventManageSection .topStack {
    @media screen and (max-width: 480px) {
        display: initial;
    }

    div.lblPart {
        width: 100px;
        margin-right: 5px;
    }

    div.ctrlPart {
        width: 400px;

        @media screen and (max-width: 480px) {
            width: 100%;
        }

        .calDescription {

            .ms-TextField-field {
                @media screen and (max-width: 480px) {
                    height: 100px;
                }
            }
        }

        .seperatorDiv {
            width: 15px;
        }

        .timeCtrl {
            float: left;


        }

        .ms-DatePicker {
            width: 100%;
            max-width: 100%;
        }

        .statusCtrl .ms-Dropdown-container .ms-Dropdown {
            width: 100%;
        }
    }
}



.eventManageSection .topStack div.hoursCtrl div.ms-Dropdown-container .ms-Dropdown {

    // padding-right: 10px;
    @media screen and (max-width: 480px) {
        width: 150px;
        margin-right: 10px;
    }
}

.eventManageSection .topStack div.minsCtrl div.ms-Dropdown-container .ms-Dropdown {

    // padding-right: 10px;
    @media screen and (max-width: 480px) {
        width: 150px;
    }
}

.eventManageSection .topStack>div.ms-StackItem {
    padding: 5px 0px 5px 0px;

    @media screen and (max-width: 480px) {
        padding: 0px;
    }
}

.eventManageSection .topStackJob>div.ms-StackItem>label {
    width: 200px;
    margin-right: 5px;
}

.eventManageSection .topStackJob>div.ms-StackItem>label {
    width: 200px;
    margin-right: 5px;

    @media screen and (max-width: 480px) {}
}

.eventManageSection .jobInfo {
    vertical-align: top;
    margin-top: -10px;

    div {
        padding-bottom: 5px;
        vertical-align: top;

        span {
            font-weight: 600;
            color: red;
        }
    }
}

.eventManageSection .topStackJob>div.ctrlPart>label {
    @media screen and (max-width: 480px) {
        width: 100%;
    }
}

.eventManageSection .topStackJob>div.ms-StackItem {
    padding: 5px 0px 5px 0px;
}

.topStackJob>div.ms-StackItem>div.ms-TextField {
    width: 50px;
}

.instructionSection {
    padding-top: 10px;

    .insHeader {
        font-weight: 600;
    }
}


.lockedEvent {
    margin-bottom: 10px;
    padding: 10px;
    text-align: center;
    background-color: antiquewhite;

    .eventMSG {
        padding-right: 5px;
        font-weight: 600;
    }


}